import React from 'react'

import { sonikaTheme, ThemeProvider } from '@sonika-se/ui-kit'

const App: React.FC = (props) => {
  const { children } = props

  return (
    <ThemeProvider theme={sonikaTheme}>
      {children}
    </ThemeProvider>
  )
}

export default App
