import React from 'react'
import { Button, Flexbox, Text, Theme } from '@sonika-se/ui-kit'
import { createUseStyles } from 'react-jss'

import App from './App'
import Logo from './Logo'

const useStyles = createUseStyles<Theme, 'content' | 'footer' | 'header' | 'link' | 'main'>((theme) => ({
  content: {
    display: 'flex',
    flex: 1,
  },
  footer: {
    background: '#DEEFFF',
    color: '#6A6A6A',
    padding: '2rem',
    ['@media (max-width: 768px)']: {
      padding: '1rem',
    },
    whiteSpace: 'nowrap',
  },
  header: {
    padding: '2rem',
    ['@media (max-width: 768px)']: {
      padding: '1rem',
    },
  },
  link: {
    textDecoration: 'none',
  },
  main: {
    minHeight: '100vh',
  },
}))

const Layout: React.FC = (props) => {
  const { children } = props

  const classes = useStyles()

  return (
    <App>
      <Flexbox className={classes.main} direction="column">
        <header className={classes.header}>
          <Flexbox align="center" justify="space">
            <Logo />
            <Button
              appearance="primary-light"
              onClick={() => window.location.replace('https://sonika.app')}
            >
              Logga in
            </Button>
          </Flexbox>
        </header>
        <div className={classes.content}>
          {children}
        </div>
        <footer className={classes.footer}>
          <Flexbox justify="space">
            <Text small>
              <a className={classes.link} href="mailto:info@sonika.se">info@sonika.se</a>
            </Text>
            <Text small>
              &copy; Sonika AB
            </Text>
          </Flexbox>
        </footer>
      </Flexbox>
    </App>
  )
}

export default Layout
